import React from 'react'
import GroundPhoto from './ontheground.jpeg';
function Graphics() {
  return (
    <div className="graphics">
      <img alt="graphic" src={GroundPhoto}></img>
    </div>
  )
}

export default Graphics
